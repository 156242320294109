/** .MuiDialogTitle-root */
.BDialog :global(.MuiDialog-paper) > :first-child {
  padding-top: 40px;
}
/** .MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root */
.BDialog :global(.MuiDialog-paper) > * {
  padding-left: 40px;
  padding-right: 40px;
}
/** .MuiDialogActions-root */
.BDialog :global(.MuiDialog-paper) > :last-child {
  padding-bottom: 40px;
}

.largerScrollArea > * > :global(.MuiPaper-root) {
  overflow: auto;
}
.largerScrollArea > * > :global(.MuiPaper-root) :global(.MuiDialogContent-root) {
  overflow-y: initial;
}
