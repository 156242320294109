//
// basic object and array manipulation functions
//

// @ts-check
/* eslint-disable func-names */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
const { get, isNil, orderBy, merge, set } = require('lodash');
const { tsAssumeMemoized } = require('./types/ts-assume');

const DEV_MODE = process.env.NODE_ENV === 'development';
/** Server-side this is same as {@link DEV_MODE}. Client-side this additionally maintains `true` for Cypress environment. */
const NON_PROD = DEV_MODE || (typeof window !== 'undefined' && Boolean(window.Cypress));

/**
 * @template T
 * @typedef {import('./type-utils').NotNullish<T>} NotNullish
 */

/** Immutable empty object `{}`. Useful for memoization when referential equality is required. */
exports.EMPTY_OBJECT = tsAssumeMemoized(Object.freeze({}));

/** Immutable empty array `[]`. Useful for memoization when referential equality is required. */
exports.EMPTY_ARRAY = tsAssumeMemoized(Object.freeze([]));

/**
 * @param {T[]} arr
 * @template T
 * @returns {T[]}
 */
exports.dedupe = function dedupe /* <T> */(arr /*: T[] */) {
  return [...new Set(arr) /* as any */]; // as T[]
};

/**
 * @param {T[]} list
 * @param {K} key
 * @returns {{ [key in import('./types/object-key-types').StringKeyWithPhantomProps<T[K]> ]: T }}
 * @template T
 * @template {import("./type-utils").KeysWithValOfType<(keyof any) | import('bson').ObjectId, T>} K
 */
exports.byKey = function byKey(list /*: T[] */, key /*: K */) {
  return Object.fromEntries(list.map((r) => [r[key] /* as any */, r])); // as { [key in Extract<T[K], keyof any>]: T }
};

/**
 * @typedef {Record<keyof any, any>} ObjNotArray
 */
/**
 * Does not change any key or value, but tries to put the keys in a preferred sort order
 * @param {T} obj
 * @param {string[]} preferredKeyOrder
 * @returns {T}
 * @template {ObjNotArray} T
 */
exports.sortKeys = function sortKeys(obj, preferredKeyOrder) {
  /**
   * @type {ObjNotArray}
   */
  const sortedObj = {};
  for (const key of preferredKeyOrder) {
    if (key in obj) {
      sortedObj[key] = obj[key];
    }
  }
  for (const key in obj) {
    if (!(key in sortedObj)) {
      sortedObj[key] = obj[key];
    }
  }
  return sortedObj;
};

/**
 * @typedef {Normal extends undefined ? IfUndefined : Normal} UndefinedSwitch
 * @template Normal, IfUndefined
 */

/**
 * @template {object} T
 * @typedef {T extends readonly any[] ? number & keyof T : keyof T} KeyOf
 */

/**
 * Similar to [`propOr`](https://ramdajs.com/docs/#propOr),
 * but only falls back to {@link ifUndefined} param if {@link key}'s value is `undefined` (or missing) from the object.
 * If object has the {@link key} as `null` then `null` will definitely be returned.
 *
 * Mainly used by `subState` and `subUseState` in `src/client/util/react-utils.js`.
 *
 * @param {Obj} stateVal
 * @param {K} key
 * @param {IfUndefined} [ifUndefined]
 * @template {Object} Obj
 * @template {KeyOf<Obj>} K
 * @template {Obj[K]} IfUndefined
 * @returns {UndefinedSwitch<Obj[K], IfUndefined>}
 */
exports.propOrDefault = function (stateVal, key, ifUndefined) {
  const val = stateVal[key];
  return val !== undefined ? val : /** @type {any} */ (ifUndefined);
};

const _SET_TO = Symbol('_SET_TO');

/**
 * Usage is similar to lodash {@link set}
 *
 * > Sets the value at path of object. If a portion of path doesn’t exist it’s created.
 *
 * with the following differences:
 *
 * - No object mutation of the input object. Only returns a new object with the specified changes.
 *   - (Faster than `set(cloneDeep(obj), propPath, value)` because it only clones the parts that may have changes.)
 * - Instead of only taking a single `propPath`, it takes an array of tuples, each with a `propPath` and a `value`.
 *
 * Examples: see unit tests for this file in `object-utils.test.mjs`
 *
 * @template {Record<string, any>} T
 */
exports.setWithCloneIfNeeded = function (
  /** @type {T} */ obj,
  /** @type {readonly (readonly [string, any])[]} */ propPathAndValueTuples,
) {
  if (NON_PROD) {
    // redundant checks that should be caught by typescript but checking again for safety
    if (!Array.isArray(propPathAndValueTuples) || !propPathAndValueTuples.every(Array.isArray)) {
      throw new Error('propPathAndValueTuples must be an array of tuples.');
    }
  }
  if (!propPathAndValueTuples.length) {
    return obj;
  }

  /** @typedef {{ [key: string]: SetSpecObj | { [_SET_TO]: any } }} SetSpecObj */
  /** @type {SetSpecObj} */
  const nestedSetTos = {};
  for (const [propPath, setTo] of propPathAndValueTuples) {
    set(nestedSetTos, propPath, { [_SET_TO]: setTo });
  }

  obj = { ...obj }; // shallow clone of parent
  _recursiveCloneAndSet(obj, nestedSetTos);

  return obj;

  function _recursiveCloneAndSet(
    /** @type {Record<string, any>} */ scope,
    /** @type {SetSpecObj} */ nestedSetTos,
  ) {
    for (const [key, nested] of Object.entries(nestedSetTos)) {
      if (_SET_TO in nested) {
        scope[key] = nested[_SET_TO];
      } else {
        const existing = scope[key];
        if (typeof existing === 'function') {
          throw new Error('No way to avoid mutating a function in setWithCloneIfNeeded.');
        }
        scope[key] =
          existing != null
            ? typeof existing === 'string'
              ? {} // consistent with lodash set, because otherwise { ...'x' } results in { 0: 'x' }
              : Array.isArray(existing)
                ? [...existing]
                : { ...existing }
            : isArrayIdx(firstKey(nested))
              ? [] // consistent with lodash set and verified with ava test
              : {};
        _recursiveCloneAndSet(scope[key], nested);
      }
    }
  }
};

function isArrayIdx(/** @type {string} */ key) {
  return `${+key}` === key && +key >= 0;
}

function firstKey(/** @type {object} */ obj) {
  return Object.keys(obj)[0];
}

/**
 * @typedef {Exclude<Parameters<typeof orderBy>[2], readonly any[] | undefined>} Direction
 */

/**
 * Note: This assumes input array is immutable. So, if no rules are specified, it will not bother cloning the array!
 *
 * Similar to lodash's `orderBy`, but allows for variable length rules more easily by
 * - filtering out `null`s from the {@link rules} and by
 * - pairing the iteratee and direction (asc/desc) as tuples instead of separate array parameters
 * @template {readonly any[] | null | undefined} T
 */
exports.sortingBy = function sortingBy(
  /** @type {T} */
  array,
  /**
   * @type {readonly (
   *     | keyof NotNullish<T>[number]
   *     | ((o: NotNullish<T>[number]) => any)
   *     | [keyof NotNullish<T>[number] | ((o: NotNullish<T>[number]) => any), Direction]
   *     | null
   *   )[]}
   */
  rules,
) {
  /**
   * @typedef {import('./schema-types').ConvertUnlessNully<T, NotNullish<T>[number][]>} ReturnType
   */
  if (array == null) {
    return /** @type {ReturnType} */ (/** @type {null | undefined} */ (array)); // null | undefined
  }
  // /** @type {Extract<Parameters<typeof orderBy>[1], any[]>} */
  const iteratees = [];
  /** @type {Direction[]} */
  const directions = [];
  for (const tuple of rules) {
    if (!tuple) continue;
    const [iteratee, direction] = Array.isArray(tuple) ? tuple : /** @type {const} */ ([tuple, 'asc']);
    iteratees.push(iteratee);
    directions.push(direction);
  }
  return /** @type {ReturnType} */ (iteratees.length === 0 ? array : orderBy(array, iteratees, directions));
};

/**
 * Returns a Map instead of an Object to preserve key insertion order
 * @template T
 * @template {string | number} K
 * @param {T[]} array
 * @param {(o: T) => K} iteratee
 */
exports.groupByMap = function groupByMap(array, iteratee) {
  /**
   * @type {Map<K, T[]>}
   */
  const result = new Map();
  for (const item of array) {
    // const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee]; // harder to add comprehensive types
    const key = iteratee(item);
    const vals = result.get(key);
    if (vals) vals.push(item);
    else result.set(key, [item]);
  }
  return result;
};

/**
 * Similar to lodash's `countBy` function but this one has better typescript.
 *
 * @template {object[] | null | undefined} Arr
 * @template {(keyof (NonNullable<Arr>[number])) | ((entry: NonNullable<Arr>[number], idx: number) => any)} KeySpec
 */
exports.countingBy = function (/** @type {Arr} */ array, /** @type {KeySpec} */ keyOrFn) {
  /**
   * @typedef {KeySpec extends ((entry: NonNullable<Arr>[number], idx: number) => any)
   *     ? ReturnType<KeySpec>
   *     : NonNullable<Arr>[number][KeySpec]
   * } ResultsKey
   */

  /** @typedef {Map<ResultsKey, number | undefined>} ResultsType */
  /** @typedef {import('./schema-types').ConvertUnlessNully<Arr, ResultsType>} _ReturnType */

  if (array == null) {
    return /** @type {_ReturnType} */ (/** @type {null | undefined} */ (array));
  }

  const keyFn = /** @type {(entry: NonNullable<Arr>[number], idx: number) => ResultsKey} */ (
    typeof keyOrFn === 'function'
      ? keyOrFn
      : (entry, idx) => entry[/** @type {keyof NonNullable<Arr>[number]} */ (keyOrFn)]
  );

  /** @type {ResultsType} */
  const counts = new Map();
  for (let i = 0; i < array.length; i++) {
    const key = keyFn(array[i], i);
    counts.set(key, (counts.get(key) || 0) + 1);
  }
  return /** @type {_ReturnType} */ (counts);
};

/**
 * This is simply `array.map(...).filter(x !== null)`.
 *
 * The advantage to doing it this way over `array.filter(...)` is that
 * it can effectively filter out alternative complex types.
 * For example, in Milestones, filtering on `deriveFromChildren` hints typescript as to which props are or are not available in subsequent filters.
 * @template T, R
 * @param {T[]} array
 * @param {(value: T, index: number) => R | null} fn
 */
exports.tsFilter = function tsFilter(array, fn) {
  const mapped = array.map(fn);
  const nullsRemoved = /** @type {Exclude<typeof mapped[number], null>[]} */ (
    mapped.filter((x) => x !== null)
  );
  return nullsRemoved;
};

/**
 * `mergeI` is short for `mergeImmutable`.
 * Similar to `lodash` `merge` but accomplishes safe merging of immutable objects.
 * because otherwise `lodash`'s `merge` mutates the first object.
 * @template {any[]} Objs
 * @param {Objs} objs
 * @returns        {Objs extends [any, any] ? Objs[0] & Objs[1]
 *           : Objs extends [any, any, any] ? Objs[0] & Objs[1] & Objs[2]
 *      : Objs extends [any, any, any, any] ? Objs[0] & Objs[1] & Objs[2] & Objs[3]
 * : Objs extends [any, any, any, any, any] ? Objs[0] & Objs[1] & Objs[2] & Objs[3] & Objs[4] : never}
 */
exports.mergeI = function (...objs) {
  // solution from https://gist.github.com/paduc/a3e95630ce8cfde35316?permalink_comment_id=2609074#gistcomment-2609074
  return merge({}, ...objs);
};

/**
 * Assign everything from `object2` into `object1`
 * @template {ObjNotArray} Obj1
 * @param {Obj1} object1
 * @param {Partial<Obj1>} object2
 */
exports.assignAll = function (object1, object2) {
  merge(object1, object2); // lodash merge mutates first object
};

/**
 * @param {string} property
 * @returns {string[]}
 */
const getPathFromPropertyString = (property) => {
  property = property.replace(/\[("|')?(\w+)("|')?\]/g, '.$2'); // convert indexes to properties
  property = property.replace(/^\./, ''); // strip a leading dot
  return property.split('.');
};

/**
 * Get a value from an object by a property path e.g. "foo.bar[0].baz"
 * @param {Record<string, any>} object A string-keyed object
 * @param {string} property A property path in dot notation (can resolve array indexes obj[1])
 *                          No optional chaining operators allowed.
 * @returns {*} The value at the property path or undefined if it doesn't exist.
 */
exports.getValueByPath = (object, property) => {
  const path = getPathFromPropertyString(property);
  let entity = {
    ...object,
  };
  let i = 0;
  while (i < path.length && entity !== undefined) {
    entity = entity?.[path[i]];
    i++;
  }
  return entity;
};

/**
 * Set a value on an object by a property path e.g. "foo.bar[0].baz"
 * @param {Record<string, any>} object A string-keyed object
 * @param {string} property A property path in dot notation (can resolve array indexes obj[1])
 *                          No optional chaining operators allowed.
 * @param {*} value The value to set at the property path
 * @param {boolean} onlyOverwriteUndefined Flag to only overwrite undefined values in object.
 * @returns {Record<string, any>} The object with the value set at the property path
 */
exports.setValueByPath = (object, property, value, onlyOverwriteUndefined = true) => {
  const path = getPathFromPropertyString(property);
  let entity = object;
  let i = 0;
  while (i < path.length - 1) {
    if (onlyOverwriteUndefined === false) {
      entity =
        typeof entity?.[path[i]] !== 'object' || entity?.[path[i]] === null
          ? (entity[path[i]] = {})
          : entity[path[i]];
    } else {
      // Default only ovewrites undefined
      entity = typeof entity?.[path[i]] === 'undefined' ? (entity[path[i]] = {}) : entity[path[i]];
    }
    i++;
  }
  entity[path[i]] = value;
  return object;
};

/**
 * @template T
 * @param {T[]} array
 * @param {string} lookupField
 * @returns {Record<string, T>} lookup map
 */
exports.createLookup = (array, lookupField = '_id') => {
  const map = {};
  for (const item of array) {
    const lookupFieldValue = get(item, lookupField);
    const key = isNil(lookupFieldValue) ? '' : lookupFieldValue.toString();
    if (key in map) {
      console.warn(`createLookup:DuplicateKey:${lookupField}:${key}: ${item?._id || item}`);
    }
    map[key] = item;
  }
  return map;
};

/**
 * Checks if the argument is empty
 * @param {any} arg
 * @returns {boolean} True if empty
 */
exports.isEmpty = (arg) => {
  if (isNil(arg)) {
    return true;
  }

  const type = typeof arg;
  if (type === 'string' || Array.isArray(arg)) {
    return arg.length === 0;
  }
  if (type === 'object') {
    if (Object.getPrototypeOf(arg) !== Object.prototype) {
      return false;
    }
    return Object.keys(arg).length === 0;
  }
  return false;
};

/**
 * @typedef {{ [key in keyof O]: Nope extends O[key] ? never : key }[keyof O]} KeysWithValNever
 * @template Nope, O
 */

/**
 * @typedef {{ [key in keyof O]: O[key] extends Nope ? never : key }[keyof O]} KeysWithValNotOnly
 * @template Nope, O
 */

/** @template {Record<any, any>} T */
exports.dropUndefinedVals = function (/** @type {T} */ obj) {
  return /** @type {{ [key in KeysWithValNotOnly<undefined, T>]: T[key] }} */ (
    Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined))
  );
};
