/** because `<DialogContentText>` is a `<p>` so therefore would not be able to contain `<p>` as children */
.imitateDialogContentText {
  margin-bottom: 12px; /* from .MuiDialogContentText-root */
  color: rgba(0, 0, 0, 0.54); /* from .MuiTypography-colorTextSecondary */

  font-size: 1rem; /* from .MuiTypography-body1 */
  font-weight: 400; /* from .MuiTypography-body1 */
  line-height: 1.5; /* from .MuiTypography-body1 */
}

.imitateDialogContentText > p:first-child {
  margin-top: 0;
}

.imitateDialogContentText > p:last-child {
  margin-bottom: 0;
}
