import React, { useState } from 'react';
import { PopupModal } from 'react-calendly';
import Button from '../Button';
import usePreventScroll from '../../../hooks/usePreventScroll';

function BookADemoButton({ className, text = 'Book a Demo', ...rest }) {
  const [popupOpened, setPopupOpened] = useState(false);
  usePreventScroll({ id: 'bookADemoButton', open: popupOpened });
  return (
    <>
      <Button {...rest} className={className} onClick={() => setPopupOpened(true)}>
        {text}
      </Button>
      <PopupModal
        url="https://calendly.com/banner-murph/intro"
        onModalClose={() => setPopupOpened(false)}
        open={popupOpened}
        rootElement={document.getElementById('root')}
      />
    </>
  );
}
export default BookADemoButton;
