import type { ITag } from '../types/tag';

export function generateBuildingTags(building: any, allTags: ITag[], tagPredicate?: (tag: ITag) => boolean) {
  const buildingID = building._id.toString();
  const tagValueMap = building.additionalFields?.tags || {};
  const hasTagValue = Object.keys(tagValueMap).length > 0;

  const tags = [];
  const buildingTags = [];
  for (const tag of allTags) {
    const { buildingIDs = ['All'], shellIDs, projectIDs, teamID, ...rest } = tag;

    if (!(buildingIDs.includes('All') || buildingIDs.includes(buildingID))) {
      continue;
    }
    if (tagPredicate?.(tag) === false) {
      continue;
    }

    tags.push(rest);
    if (hasTagValue) {
      const tagID = tag._id.toString();
      const tagValue = tagValueMap[tagID];
      if (tagValue) {
        buildingTags.push({
          id: tagID,
          type: tag.type,
          location: tag.location,
          name: tag.name,
          value: tagValue,
          dataType: tag.dataType,
          viewRoles: tag.viewRoles,
          editRoles: tag.editRoles,
        });
      }
    }
  }

  return { tags, buildingTags };
}
