import type * as SettingsNotifications from 'banner-typescript-rest-api/src/models/settings-notifications';
import type { modelPersonalPrefs_manager_update } from 'banner-typescript-rest-api/src/models/shared/personal-prefs';
import type { CustomTasksService } from 'banner-typescript-rest-api/src/services/CustomTasksService';
import type * as SettingsCurrencies from '../../../server-ts/src/models/settings-currencies';
import type { BidController } from '../../server/controllers/bidController';
import type { BuildingController } from '../../server/controllers/buildingController';
import type { ContractsController } from '../../server/controllers/contractsController';
import type { IntegrationController } from '../../server/controllers/integrationController';
import type { LogsController } from '../../server/controllers/logsController';
import type { ProfileController } from '../../server/controllers/profileController';
import type { ProjectController } from '../../server/controllers/projectController';
import type { ShellController } from '../../server/controllers/shellController';
import type { ShellControllerHelper } from '../../server/controllers/shellControllerHelper';
import type { TeamController } from '../../server/controllers/teamController';
import type { SrcServerRes as JsRes, ServerTsRes as TsRes, _MakeApiFns } from './api-context';
import type { AutomationController } from '../../server/controllers/automationController';

export default function _apiFnsWrite(makeApiFns: Omit<_MakeApiFns, 'get'>) {
  const { post, extend } = makeApiFns;

  // prettier-ignore
  // keeping these all single line so we can easily use VSCode "Sort Ascending" feature
  const plainApiCalls = {
    '/api/custom-tasks/remove-task-type': post('/api/custom-tasks/remove-task-type')<TsRes<CustomTasksService['removeTaskType']>>(),
    '/api/custom-tasks/submit-task-type': post('/api/custom-tasks/submit-task-type')<TsRes<CustomTasksService['submitTaskType']>>(),
    createActivity: post('/api/plus/teams/createActivity')<any, JsRes<TeamController['createActivity']>>(),
    createInvoiceForProject: post('/api/plus/managers/createInvoiceForProject')<any, JsRes<ContractsController['createInvoiceForProject']>>(),
    dashboardPositionUpdate: post('/api/plus/managers/dashboardPositionUpdate')<any, JsRes<BuildingController['dashboardPositionUpdate']>>(),
    dashboardSectionAdd: post('/api/plus/managers/dashboardSectionAdd')<any, JsRes<BuildingController['dashboardSectionAdd']>>(),
    dashboardSectionDelete: post('/api/plus/managers/dashboardSectionDelete')<any, JsRes<BuildingController['dashboardSectionDelete']>>(),
    dashboardSectionRename: post('/api/plus/managers/dashboardSectionRename')<any, JsRes<BuildingController['dashboardSectionRename']>>(),
    dashboardSectionShellAdd: post('/api/plus/managers/dashboardSectionShellAdd')<any, JsRes<BuildingController['dashboardSectionShellAdd']>>(),
    dashboardSectionShellSync: post('/api/plus/managers/dashboardSectionShellSync')<any, JsRes<BuildingController['dashboardSectionShellSync']>>(),
    errorCenterResend: post('/api/plus/errors/resend')<any, JsRes<IntegrationController['errorCenterResend']>>(),
    errorCenterResolve: post('/api/plus/errors/resolve')<any, JsRes<LogsController['resolveError']>>(),
    errorCenterReset: post('/api/plus/errors/reset')<any, JsRes<LogsController['resetError']>>(),
    generateMilestoneTemplateForShell: post('/api/plus/managers/generateMilestoneTemplateForShell')<any, JsRes<ShellController['generateMilestoneTemplateForShell']>>(),
    personalPrefsUpdate: post('/api/shared/personal/prefs/update')<any, TsRes<typeof modelPersonalPrefs_manager_update>>(),
    rejectUploadByBannerDvTeam: post('/api/plus/data/rejectUploadByBannerDvTeam')<any, JsRes<ProjectController['rejectUpload']>>(),
    settingsCurrenciesBuildingDelete: post('/api/v3/settings/currencies/buildings/delete')<any, TsRes<typeof SettingsCurrencies.deleteBuildingCurrencies>>(),
    settingsCurrenciesBuildingsSubmit: post('/api/v3/settings/currencies/buildings/submit')<any, TsRes<typeof SettingsCurrencies.submitBuildingCurrencies>>(),
    settingsCurrenciesGlobalDelete: post('/api/v3/settings/currencies/global/delete')<any, TsRes<typeof SettingsCurrencies.deleteGlobalCurrency>>(),
    settingsCurrenciesGlobalEdit: post('/api/v3/settings/currencies/global/edit')<any, TsRes<typeof SettingsCurrencies.editGlobalCurrency>>(),
    submitQuickBid: post('/api/plus/managers/submitQuickBid')<any, JsRes<BidController['submitQuickBid']>>(),
    updateProjectStatusFilter: post('/api/plus/contractors/updateProjectStatusFilter')<any, JsRes<ProfileController['updateProjectStatusFilter']>>(),
    updateRenoUnitTags: post('/api/plus/managers/updateRenoUnitTags')<JsRes<ShellControllerHelper['updateRenoUnitTags']>>(),
    updateShellUnitField: post('/api/plus/managers/updateShellUnitField')<JsRes<ShellController['updateShellUnitField']>>(),
    '/api/integration/updateTagsBulk': post('/api/integration/updateTagsBulk')<JsRes<IntegrationController['updateTagsBulk']>>(),
    vendors_addTeamMember: post('/api/plus/vendors/addTeamMember')<any, JsRes<TeamController['addTeamMember']>>(),
    updateOriginalBudget: post('/api/plus/manager/updateOriginalBudget')<any, JsRes<ShellController['updateOriginalBudget']>>(),
    '/api/plus/managers/upsertAutomationSettings': post('/api/plus/managers/upsertAutomationSettings')<JsRes<AutomationController['upsertAutomationSettings']>>(),
    '/api/plus/managers/removeAutomationSettings': post('/api/plus/managers/removeAutomationSettings')<JsRes<AutomationController['removeAutomationSettings']>>(),
    '/api/plus/managers/runAutomations': post('/api/plus/managers/runAutomations')<JsRes<AutomationController['removeAutomationSettings']>>(),
    addNotification: post('/api/v3/settings/notifications')<TsRes<typeof SettingsNotifications.addNotification>>(),
  }

  // transforming functions:
  // (nothing for write yet, but we have examples in _apiFnsRead.ts)

  return {
    ...plainApiCalls,
  };
}
