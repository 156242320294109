import React from 'react';
import { getYear } from 'date-fns';
import { now } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ButtonLink from '../../../common/ButtonLink';
import { LinkButtonMixin } from '../../../common/ButtonLink/ButtonLink';
import SocialMedia from '../../../common/SocialMedia';
import SVGSocialButton from '../../../common/SocialMedia/SVGSocialButton';
import { WhiteBannerIcon } from '../../../Icons';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import { LayoutMixin } from '../../../../theme';
import theme from '../../../../theme/theme';

const StyledWrapper = styled.div`
  background-color: ${theme.colors.Mindnight};
  padding: 64px 0 24px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    padding-bottom: 36px;
  }
`;
const StyledWrapperContent = styled.div`
  ${LayoutMixin};
  display: flex;
  flex-direction: column;
`;
const StyledInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    flex-direction: row;
  }
`;
const StyledBanner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 64px;
  @media screen and (min-width: ${theme.breakpoints[768]}) {
    display: flex;
    flex-direction: row;
    gap: 54px;
    margin-bottom: 56px;
  }
`;
const StyledBannerSocial = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 64px;
  @media screen and (min-width: ${theme.breakpoints[768]}) {
    display: flex;
    flex-direction: column;
    gap: 64px;
    justify-content: flex-start;
    margin-bottom: 56px;
  }
`;
const StyledTitleList = styled.h1`
  font-family: ${theme.fontFamily.Inter};
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.White};
  margin-bottom: 24px;
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledButtonLink = styled(ButtonLink)`
  font-size: 16px;
  color: ${theme.colors.White};
`;
const StyledButtonAnchor = styled.a`
  ${LinkButtonMixin}
  font-size: 16px;
  color: ${theme.colors.White};
`;
const StyledAContentBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${theme.colors['Paper White']};
  }
`;
const StyledItem = styled(Link)`
  font-family: ${theme.fontFamily.Inter};
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.colors.White};
  margin-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;
const StyledTerms = styled.span`
  font-family: ${theme.fontFamily.Inter};
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.colors.White};
  margin-top: 24px;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    margin-top: 20px;
    padding: unset;
  }
`;
const WrapperTerms = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors['Paper White']};

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    border-bottom: unset;
    width: min-content;
    gap: 40px;
    justify-content: center;
    display: flex;
  }
`;
function Footer({ className }) {
  const { scrollToTop } = useScrollToTop();
  return (
    <StyledWrapper className={className}>
      <StyledWrapperContent>
        <StyledInnerWrapper>
          <StyledBannerSocial>
            <SVGSocialButton onClick={scrollToTop}>
              <WhiteBannerIcon />
            </SVGSocialButton>
            <SocialMedia />
          </StyledBannerSocial>

          <StyledBanner>
            <StyledContent>
              <StyledTitleList>Company</StyledTitleList>
              {/* <StyledButtonLink to="" big>
                About Us
              </StyledButtonLink> */}
              <StyledButtonLink
                to={{ pathname: 'https://www.linkedin.com/company/withbanner/jobs/' }}
                target="_blank"
                big
              >
                Careers
              </StyledButtonLink>
              {/* <StyledButtonLink to="/blog" aria-label="go to blog" big>
                Blog
              </StyledButtonLink> */}
            </StyledContent>

            <StyledContent>
              <StyledTitleList>Contact Us</StyledTitleList>
              <StyledButtonAnchor href="mailto:info@withbanner.com" $big $underline>
                info@withbanner.com
              </StyledButtonAnchor>
              <StyledButtonAnchor href="tel:+4152317512" $big $underline>
                (415) 231-7512
              </StyledButtonAnchor>
            </StyledContent>
          </StyledBanner>
        </StyledInnerWrapper>
        <StyledAContentBottom>
          <StyledTerms>© {getYear(now())} Banner. All rights reserved.</StyledTerms>
          <WrapperTerms>
            <StyledItem to="/terms">Terms of use</StyledItem>
            <StyledItem to="/privacy">Privacy Policy</StyledItem>
            <StyledItem to="/acceptable">Acceptable Use</StyledItem>
          </WrapperTerms>
        </StyledAContentBottom>
      </StyledWrapperContent>
    </StyledWrapper>
  );
}
export default Footer;
